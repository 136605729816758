import React, { Fragment } from "react";
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    left: 0,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

function Menu(props) {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const { open, toggleDrawer } = props;

  const handleViewRooms = (room) => {
    history.push(`/admin/rooms`);
  };


  const handleViewMessages = (room) => {
    history.push(`/admin/messages`);
  };


  return (
    <Fragment>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={(e) => toggleDrawer(false, e)}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={(e) => toggleDrawer(false, e)}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem onClick={handleViewMessages} button>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText>Messages</ListItemText>
          </ListItem>
          <ListItem onClick={handleViewRooms} button>
            <ListItemIcon>
              <LocalPharmacyIcon />
            </ListItemIcon>
            <ListItemText>Patient Rooms</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </Fragment>
  );
}

export default Menu;
