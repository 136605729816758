import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { db } from "../firebase";
import { useHistory } from "react-router-dom";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import Menu from "./Menu";
import { auth } from "../firebase";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grid: {
    padding: "2em",
  },
  buttons: {
    marginLeft: "auto",
  },
  typo: {
    padding: ".5em",
  },
});

export default function MessageList() {
  const classes = useStyles();
  const [messages, setMessages] = useState();
  let history = useHistory();

  useEffect(() => {
    const getMeetingRooms = async () => {
      try {
        let response = await db.collection("messages").get();
        let messagesObject = response.docs.map((doc) => doc.data());
        setMessages(() => {
          return messagesObject;
        });
      } catch (e) {
        console.log(e);
      }
    };
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getMeetingRooms();
      } else {
        history.push("/login");
      }
    });
  }, [history]);

  const handleDeleteMessage = async (room) => {
    try {
      let deleteQuery = db.collection("messages").where("id", "==", room.id);
      let deleteResponse = await deleteQuery.get();
      deleteResponse.forEach((room) => {
        room.ref.delete();
      });
      let response = await db.collection("messages").get();
      let messagesObject = response.docs.map((doc) => doc.data());
      setMessages(() => {
        return messagesObject;
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <Menu />
      <Grid className={classes.grid} container spacing={0}>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            {messages &&
              messages.map((message, key) => {
                return (
                  <Grid key={key} item xs={12}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.typo}
                          variant="h6"
                          align="left"
                        >
                          {message.name}
                        </Typography>
                        <Divider />
                        <Typography className={classes.typo} align="left">
                          Email: {message.email}
                        </Typography>
                        <Typography className={classes.typo} align="left">
                          Phone Number: {message.phone}
                        </Typography>
                        <Typography className={classes.typo} align="left">
                          Message: {message.message}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          onClick={() => handleDeleteMessage(message)}
                          className={classes.buttons}
                          align="center"
                          size="small"
                        >
                          <DeleteRounded />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
