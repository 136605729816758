import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import AccessPharmImage from "../images/logo.jpg";
import ConnectImage from "../images/connect.png";
import Signup from "../images/sign up.jpg";
import Payment from "../images/payment.jpg";
import Footer from "./Footer.jsx";
import Divider from "@material-ui/core/Divider";
import { auth, db, functions } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import moment from "moment";
import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";
import { toast } from "react-toastify";
import uuid from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../images/door.gif";

const services = [
  "Diabetes",
  "Heart Ailments",
  "Arthritis",
  "Asthma",
  "Pain Relief",
  "Drug interactions/Side Effects",
  "Precautions/Adverse Effects",
  "Healthy Lifestyle Habits",
  "Cold/Flu Symptoms Relief",
];

const tiers = [
  {
    title: "Standard",
    subheader: "1 Quick Meeting",
    price: "20",
    description: [
      "1 consultation included",
      "Speak Face to Face with a Pharmacist Now",
      "Money Back Guarentee",
    ],
    buttonText: "Select",
    buttonVariant: "outlined",
  },
  {
    title: "Subscription",
    subheader: "Most popular",
    price: "160",
    description: [
      "3 month subscription",
      "8 consultations included",
      "1 free consultation",
    ],
    buttonText: "select",
    buttonVariant: "contained",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: "#12bbaa",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function Homepage() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [profile, setProfile] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const Button = styled(MuiButton)(spacing);
  const classes = useStyles();

  let timer1 = setTimeout(() => setLoadingPage(false), 3000);
  useEffect(() => {
    return () => {
      clearTimeout(timer1);
    };
  }, [loadingPage]);
  const checkForSubscription = async () => {
    if (user) {
      const profile = await db.collection("profile").doc(user.uid).get();
      let profileData = profile.data();
      if (profileData.subscriber && profileData.numberOfMeetings > 0) {
        setProfile(profileData);
        setOpen(true);
      } else {
        history.push("/pricing");
      }
    } else {
      history.push("/login");
    }
  };

  const createMeeting = async () => {
    try {
      const meetingId = uuid.v4();

      const meeting = {
        id: meetingId,
        paymentProcessed: true,
        userName: profile.firstName + " " + profile.lastName,
        paymentId: "none",
        meetingDate: moment(new Date()).format(),
      };
      console.log("creating meeting");
      await db.collection("meetings").add(meeting);
      console.log("meeting created");

      await db
        .collection("profile")
        .doc(user.uid)
        .update({ numberOfMeetings: profile.numberOfMeetings - 1 });

      history.push("/chat/" + meetingId);
    } catch (e) {
      console.error(e);
      toast.error(
        "There was an error creating your meeting. Please try again later."
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loadingPage ? (
        <img
          src={logo}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
            height: "80%",
          }}
          alt="loading..."
        />
      ) : (
        <>
          <Container maxWidth="lg">
            <Box py={10}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Connect Face to Face with a Pharmacist
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                At Access Pharmacy Care Services we provide quick and easy
                access to a Doctor of Pharmacy.
              </Typography>
              <Box py={4}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={checkForSubscription}
                      color="primary"
                    >
                      Connect with a Pharmacist
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => history.push("/contactus")}
                      color="primary"
                    >
                      Contact Us
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider />
            <br />
            <Grid container spacing={2} justify="center">
              <Grid item md={8}>
                <Box>
                  <img
                    src={AccessPharmImage}
                    style={{ maxWidth: "90%", maxHeight: "80%" }}
                    alt="Logo"
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box py={4}>
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Quick Access to a Pharmacist
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight="fontWeightRegular">
                      Using the resources provided by APCS, you can quickly
                      connect face to face with a pharmacist online.
                    </Box>
                  </Typography>
                </Box>
                <Box py={4}>
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Answers to your Questions
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight="fontWeightRegular">
                      Our knowledgeable health care providers can provide you
                      fast answers to the most important questions about your
                      health
                    </Box>
                  </Typography>
                </Box>
                <Box py={4}>
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Skip the Line
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight="fontWeightRegular">
                      No one has time to wait in lines when it comes to their
                      health. Our goal is to provide fast and reliable care
                      without the long lines and busy stores.
                    </Box>
                  </Typography>
                </Box>
                <Box py={4}>
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Your Personal Pharmacist
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight="fontWeightRegular">
                      Our pharmacists build relationships, and strive for
                      excellence in service to our customers.
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <br />
            <Grid container spacing={2} justify="center">
              <Grid item xs={12}>
                <Typography variant="h5">
                  <Box textAlign="center" fontWeight="fontWeightBold">
                    How to Get Started
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box textAlign="center" py={1}>
                  <img
                    src={Signup}
                    style={{
                      borderRadius: "10px",
                      width: "275px",
                      height: "175px",
                    }}
                    alt="signup"
                  />
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Sign Up for APCS
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight={500}>
                      Setup your profile in a few simple steps
                    </Box>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box textAlign="center" py={1}>
                  <img
                    src={Payment}
                    style={{
                      borderRadius: "10px",
                      width: "275px",
                      height: "175px",
                    }}
                    alt="payment"
                  />
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Explore Pricing Options
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight={500}>
                      Choose between a quick visit or a 3 month subscription
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box textAlign="center" py={1}>
                  <img
                    src={ConnectImage}
                    style={{
                      borderRadius: "10px",
                      width: "275px",
                      height: "175px",
                    }}
                    alt="connect"
                  />
                  <Typography component={"span"} variant="h4">
                    <Box py={1} fontWeight="fontWeightBold">
                      Connect in a Video Call
                    </Box>
                  </Typography>
                  <Typography component={"span"} variant="h6">
                    <Box py={1} fontWeight={500}>
                      A pharmacist will connect with you to discuss your
                      medications and questions
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box py={3}>
              <Typography variant="h5">
                <Box textAlign="center" fontWeight="fontWeightBold">
                  Pricing
                </Box>
              </Typography>
              <Box py={3}>
                <Grid container spacing={5} alignItems="flex-end">
                  {tiers.map((tier) => (
                    // Enterprise card is full width at sm breakpoint
                    <Grid item key={tier.title} xs={12} md={6}>
                      <Card>
                        <CardHeader
                          title={tier.title}
                          subheader={tier.subheader}
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          className={classes.cardHeader}
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <Typography
                              component="h2"
                              variant="h3"
                              color="textPrimary"
                            >
                              ${tier.price}
                            </Typography>
                          </div>
                          <ul>
                            {tier.description.map((line) => (
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={line}
                              >
                                {line}
                              </Typography>
                            ))}
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <br />
            <br />
            <Footer />
          </Container>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Subscription Popup"
            aria-describedby="Subscription Popup"
          >
            <Container maxWidth="md">
              <Box py={3}>
                <Typography component={"span"} variant="h5">
                  <Box py={1} fontWeight="fontWeightBold">
                    Subscription Information
                  </Box>
                </Typography>
                <Typography component={"span"} variant="body1">
                  <Box py={1} fontWeight={500}>
                    Thank you for being a subscriber!
                  </Box>
                </Typography>
                <Typography component={"span"} variant="body1">
                  <Box fontWeight={500}>
                    You may use your purchased meetings here.
                  </Box>
                </Typography>
                <Box py={2}>
                  <Divider />
                </Box>
                <Typography component={"span"} variant="body1">
                  <Box fontWeight={500}>
                    {`Number of Meetings Available : ${profile.numberOfMeetings}`}
                  </Box>
                </Typography>
                <Typography component={"span"} variant="body1">
                  <Box py={1} fontWeight={500}>
                    {`Subscription End Date : ${moment(
                      profile.subscriptionEndDate
                    ).format("dddd MMMM D Y")}`}
                  </Box>
                </Typography>
                <Divider />
                <Box
                  py={2}
                  display="flex"
                  justifyContent="space-between"
                  mx="auto"
                >
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={createMeeting}
                    color="primary"
                  >
                    Create Meeting
                  </Button>
                </Box>
              </Box>
            </Container>
          </Dialog>
        </>
      )}
    </>
  );
}
