import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import Typography from "@material-ui/core/Typography";
import { db } from "../firebase";
import { useHistory } from "react-router-dom";
import MeetingRoomRounded from "@material-ui/icons/MeetingRoomRounded";
import { auth } from "../firebase";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grid: {
    padding: "2em",
  },
  typo: {
    padding: ".5em",
  },
  buttons: {
    marginLeft: "auto",
  },
});

export default function AdminPanel() {
  const classes = useStyles();
  const [chatrooms, setChatRooms] = useState();

  const [user, loading] = useAuthState(auth);
  let history = useHistory();

  useEffect(() => {
    const getMeetingRooms = async () => {
      try {
        let response = await db.collection("meetings").get();
        let chatroomsObject = response.docs.map((doc) => doc.data());
        setChatRooms(() => {
          return chatroomsObject;
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!loading) {
      if (user) {
        getMeetingRooms();
      } else {
        history.push("/login");
      }
    }
  }, [history, user, loading]);

  const handleJoinRoom = (room) => {
    history.push(`/chat/${room.id}`);
  };

  const handleCloseRoom = async (room) => {
    try {
      let deleteQuery = db.collection("meetings").where("id", "==", room.id);
      let deleteResponse = await deleteQuery.get();
      deleteResponse.forEach((room) => {
        room.ref.delete();
      });
      let response = await db.collection("meetings").get();
      let chatroomsObject = response.docs.map((doc) => doc.data());
      setChatRooms(() => {
        return chatroomsObject;
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      {chatrooms && (
        <Fragment>
          <Grid className={classes.grid} container spacing={0}>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                {chatrooms &&
                  chatrooms.map((room, key) => {
                    return (
                      <Grid key={key} item xs={12}>
                        <Card className={classes.root}>
                          <CardContent>
                            <Typography
                              className={classes.typo}
                              variant="h6"
                              align="left"
                            >
                              MEETING ROOM : {room.userName}
                            </Typography>
                            <Divider />
                            <Typography className={classes.typo} align="left">
                              MeetingDate:{" "}
                              {moment(room.meetingDate).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              onClick={() => handleJoinRoom(room)}
                              align="center"
                              size="small"
                              className={classes.buttons}
                            >
                              <MeetingRoomRounded />
                            </Button>
                            <Button
                              onClick={() => handleCloseRoom(room)}
                              align="center"
                              size="small"
                              className={classes.buttons}
                            >
                              <DeleteRounded />
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}
