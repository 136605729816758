import React from "react";
import { Route, Redirect, BrowserRouter, Switch } from "react-router-dom";
import Homepage from "./components/Homepage";
import Chatroom from "./components/Chatroom";
import Login from "./components/Login";
import AdminPanel from "./components/AdminPanel";
import ContactUs from "./components/ContactUs";
import PaymentForm from "./components/PaymentForm";
import PaymentConfirmation from "./components/PaymentConfirmation";
import Signup from "./components/Signup";
import Pricing from "./components/Pricing";
import Profile from "./components/Profile";
import "./App.css";
import NavBar from "./components/NavBar";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MessageList from "./components/MessageList";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(
  "pk_test_51H6dF3CvyJyv0gPzUWUmA7JDVLAryjLjzCn3HZ1P9RHH0sPPhseJ9XyMaJgavdya2NAw92uz16EqEWEzTHW0qXAU00nZiOndjk"
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(18, 187, 170)",
      mainGradient:
        "linear-gradient(to right, rgb(18, 187, 170), rgb(25, 118, 210)",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <NavBar theme={theme} />
            <Route exact path="/" component={Homepage} />
            <Switch>
              <Route path="/pricing" component={Pricing} />
            </Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/contactus" component={ContactUs} />
            <Route path="/profile" component={Profile} />
            <Route path="/chat/:id" component={Chatroom} />
            <Route path="/admin">
              <Redirect to="/admin/rooms" />
            </Route>
            <Route path="/admin/rooms" component={AdminPanel} />
            <Route path="/payment" component={PaymentForm} />
            <Route path="/payments/:id" component={PaymentConfirmation} />
            <Route path="/admin/messages" component={MessageList} />
          </BrowserRouter>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Elements>
      </ThemeProvider>
    </div>
  );
}

export default App;
