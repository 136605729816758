import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";

import StarIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthState } from "react-firebase-hooks/auth";
import uuid from "uuid";
import { functions, db, auth } from "../firebase";
import { toast } from "react-toastify";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: "Standard",
    subheader: "1 Quick Meeting",
    price: "20",
    description: [
      "1 consultation included",
      "Speak Face to Face with a Pharmacist Now",
      "Money Back Guarentee",
    ],
    buttonText: "Select",
    buttonVariant: "outlined",
  },
  {
    title: "Subscription",
    subheader: "Most popular",
    price: "160",
    description: [
      "3 month subscription",
      "8 consultations included",
      "1 free consultation",
    ],
    buttonText: "select",
    buttonVariant: "contained",
  },
];

const MeetingInfo = (props) => {
  const classes = useStyles();
  const [user, loading] = useAuthState(auth);
  const [redirecting, setRedirecting] = useState(false);
  let history = useHistory();

  const submitPayment = async (paymentType) => {
    console.log(paymentType);

    try {
      setRedirecting(true);
      const meetingId = uuid.v4();
      const paymentId = uuid.v4();
      const profile = await db.collection("profile").doc(user.uid).get();
      let profileData = profile.data();
      const meeting = {
        id: meetingId,
        question: "test",
        medications: "none",
        healthIssues: "none",
        userName: profileData.firstName + " " + profileData.lastName,
        paymentId: paymentId,
        meetingDate: moment(new Date()).format(),
      };
      console.log("creating meeting");
      await db.collection("meetings").add(meeting);
      console.log("meeting created");
      const placeholder = "placeholder";
      let payment = {
        meetingId: meetingId,
        sessionId: placeholder,
        orderId: placeholder,
        paymentProcessed: false,
        paymentType: paymentType,
        paymentId: paymentId,
        uid: user.uid,
      };
      console.log("creating payment" + JSON.stringify(payment));
      await db.collection("payments").doc(paymentId).set(payment);

      let createSession = await functions.httpsCallable("createPaymentSession");
      console.log("Break before creating payment");
      let session = await createSession({
        paymentId: paymentId,
        paymentType: paymentType,
      });
      console.log("Payment Created");
      console.log(JSON.stringify(session.data.result.paymentLink));
      await db.collection("payments").doc(paymentId).update({
        sessionId: session.data.result.paymentLink.id,
        orderId: session.data.result.paymentLink.orderId,
      });
      console.log("updating payment");
      //   When the customer clicks on the button, redirect them to Checkout.
      window.location.replace(session.data.result.paymentLink.url);
    } catch (e) {
      console.error(e);
      setRedirecting(false);
      toast.error(
        "There was an error creating your payement. Please try again later."
      );
    }
  };

  useEffect(() => {
    if (!loading) {
      if (!user) {
        history.push("/login");
      }
    }
  }, [history, user, loading]);

  return (
    <>
      {/* End hero unit */}
      {redirecting && (
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          width="100%"
          zIndex="9999"
        >
          <CircularProgress />
        </Box>
      )}
      {!redirecting && (
        <Container maxWidth="md" component="main">
          <Box py={3}>
            <Typography variant="h3"> Consult a Pharmacist</Typography>

            <Typography variant="body1">
              Once your payment is processed you will be redirected to a meeting
              room.
            </Typography>
          </Box>
          <Box py={3}>
            <Grid container spacing={5} alignItems="flex-end">
              {tiers.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid item key={tier.title} xs={12} md={6}>
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      action={tier.title === "Pro" ? <StarIcon /> : null}
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="textPrimary"
                        >
                          ${tier.price}
                        </Typography>
                      </div>
                      <ul>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        variant={"contained"}
                        color="primary"
                        onClick={() => submitPayment(tier.title)}
                      >
                        {tier.buttonText}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </Container>
      )}
    </>
  );
};

export default MeetingInfo;
