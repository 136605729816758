import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      Access Pharmacy Care Services
      {" " + new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <>
      <Container padding={6} maxWidth="lg">
        <Divider />
        <Box py={2} textAlign="center">
          <IconButton
            href="https://www.instagram.com/apcs4u/"
            aria-label="instagram"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            href="https://www.facebook.com/Access-Pharmacy-Care-Services-LLC-108737650955085"
            aria-label="facebook"
          >
            <FacebookIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box py={2} textAlign="center">
          <Typography variant="h6" align="center" gutterBottom>
            Your best care, because we care
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Disclaimer: all information, knowledge, advice, and counseling
            points provided herein is provided by licensed pharmacist and based
            on the most current therapy guidelines surrounding a given disease
            state, relevant clinical trials, and\or sound clinical judgment. No
            information given should be used in place of that given by a users
            personal health care provider and/or pharmacist. This information
            should not be used for diagnostic purposes. All Access Pharmacy Care
            Services personnel and staff retain no liability for information
            provided.
          </Typography>

          <Copyright />
        </Box>
      </Container>
    </>
  );
}
