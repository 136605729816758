import React, { useState, useEffect } from "react";
import { functions, db, auth } from "../firebase";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
const stripePromise = loadStripe(
  "pk_test_51H6dF3CvyJyv0gPzUWUmA7JDVLAryjLjzCn3HZ1P9RHH0sPPhseJ9XyMaJgavdya2NAw92uz16EqEWEzTHW0qXAU00nZiOndjk"
);

export default function PaymentConfirmation(props) {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const { match } = props;
  const query = new URLSearchParams(window.location.search);
  const paymentId = match !== undefined ? match.params.id : undefined;
  const history = useHistory();
  const [user, loadingUser] = useAuthState(auth);

  useEffect(() => {
    async function fetchClientSecret() {
      if (query.get("success")) {
        try {
          const payment = await db.collection("payments").doc(paymentId).get();
          let paymentData = payment.data();
          console.log(paymentData.paymentProcessed);
          if (paymentData.paymentProcessed) {
            toast.error(
              "This payment has been processed. Please check your email to find a link to the meeting."
            );
            history.push("/");
          } else {
            console.log("trying to get payment");
            let getSession = await functions.httpsCallable("getPaymentSession");
            let session = await getSession({
              orderId: paymentData.orderId,
            });
            console.log(session);
            if (session.data.order.state === "OPEN") {
              if (paymentData.paymentType === "Subscription") {
                let date = moment(new Date());
                let endDate = date.clone().add(3, "month");
                try {
                  await db.collection("profile").doc(user.uid).update({
                    subscriber: true,
                    numberOfMeetings: 9,
                    subscriptionStartDate: date.format(),
                    subscriptionEndDate: endDate.format(),
                  });
                  toast.success("Successfully add your subscription!");
                } catch (e) {
                  console.error(e);
                  toast.error(
                    "There was an error updating your account subscription."
                  );
                }
              }
              await db
                .collection("payments")
                .doc(paymentId)
                .update({ paymentProcessed: true });
              history.push("/chat/" + paymentData.meetingId);
            }
          }
        } catch (e) {
          setLoading(false);
          console.error(e);
          toast.error(
            "There was an error checking the status of your payment. Please contact us for assistance."
          );
        }
      }

      if (query.get("canceled")) {
        setLoading(false);
      }
    }
    if (!loadingUser) {
      fetchClientSecret();
    }
  }, [success, paymentId, query, history, user, loadingUser]);

  const handleResubmit = async () => {
    try {
      const payment = await db.collection("payments").doc(paymentId).get();
      let paymentData = payment.data();
      //todo fix this
    } catch (e) {
      toast.error(
        "There was an error trying to resubmit your payment. Please contact us or try again later."
      );
    }
  };

  return (
    <>
      <Container align="center">
        {loading ? (
          <Box py={3}>
            <CircularProgress />
          </Box>
        ) : success ? (
          <Box py={3}>
            <Typography>Your payment was successful</Typography>
          </Box>
        ) : (
          <Box py={3}>
            <Typography>
              Your payment was not successful. Please try to submit your payment
              again
            </Typography>
            <Box py={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleResubmit}
              >
                Submit Payment
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
}
