import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { db } from "../firebase";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "./TextField";
import moment from "moment";
import { toast } from "react-toastify";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const formSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  password: Yup.string(),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match if you choose to change them."
  ),
});

const Profile = (props) => {
  const [profile, setProfile] = useState({});
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [loadingSpinner, setloadingSpinner] = useState(true);
  const [user, loading] = useAuthState(auth);
  let initialValidation = useRef(true);

  const handleSubmitClick = async (values) => {
    try {
      if (values.password) {
        try {
          await user.updatePassword(values.password);
        } catch (e) {
          toast.error(`There was an error updating your password.`);
        }
      }
      await db
        .collection("profile")
        .doc(user.uid)
        .set({
          ...profile,
          firstName: values.firstName,
          lastName: values.lastName,
        });
      toast.success("Successfully saved profile!");
      history.go(0);
    } catch (e) {
      toast.error(`There was an error saving your profile.`);
    }
  };

  useEffect(() => {
    const userCheck = async () => {
      try {
        if (user) {
          const fbProfile = await db.collection("profile").doc(user.uid).get();
          const profileData = fbProfile.data();
          console.log(profileData);
          setProfile(profileData);
          setloadingSpinner(false);
        } else {
          toast.error(
            "Error retrieving logged in user information. Please try again later."
          );
          history.push("/login");
        }
      } catch (e) {
        toast.error("You must sign in before viewing a profile.");
        history.push("/login");
      }
    };
    if (!loading) {
      userCheck();
    }
  }, [history, user, loading]);

  return (
    <Formik
      initialValues={{ ...profile, password: "", passwordConfirmation: "" }}
      validationSchema={formSchema}
      enableReinitialize
      onSubmit={(values) => {
        handleSubmitClick(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        validateOnBlur,
        validateForm,
        isSubmitting,
        isValid,
      }) => {
        if (initialValidation.current) {
          validateForm();
          initialValidation.current = false;
        }
        return (
          <>
            {loadingSpinner && (
              <Container
                style={{ paddingTop: "2em", textAlign: "center" }}
                maxWidth="sm"
              >
                <CircularProgress />
              </Container>
            )}
            {!loadingSpinner && edit && (
              <Container style={{ paddingTop: "2em" }} maxWidth="sm">
                <Box
                  py={3}
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Typography variant="h2" color="textPrimary">
                    Edit Profile
                  </Typography>
                  <Box
                    paddingTop={2}
                    display="flex"
                    flexDirection="row"
                    component="div"
                    justifyContent="flex-end"
                  >
                    <IconButton
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box py={3}>
                  <TextField
                    name="firstName"
                    label="First Name"
                    inputProps={{ maxLength: 30 }}
                  />
                  <TextField
                    name="lastName"
                    label="Last Name"
                    inputProps={{ maxLength: 30 }}
                  />
                  <TextField
                    name="password"
                    label="New Password"
                    inputProps={{ maxLength: 100 }}
                  />
                  <TextField
                    name="passwordConfirmation"
                    label="Confirm Password"
                    inputProps={{ maxLength: 100 }}
                  />
                  <Button
                    variant="contained"
                    justify="space-evenly"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    Save
                  </Button>
                </Box>
              </Container>
            )}
            {!loadingSpinner && !edit && (
              <Container style={{ paddingTop: "2em" }} maxWidth="sm">
                <Box
                  py={3}
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Typography variant="h2" color="textPrimary">
                    {profile.firstName + " " + profile.lastName}
                  </Typography>
                  <Box
                    paddingTop={2}
                    display="flex"
                    flexDirection="row"
                    component="div"
                    justifyContent="flex-end"
                  >
                    <IconButton
                      onClick={() => {
                        setEdit(true);
                      }}
                    >
                      <EditIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box py={1} display="flex" flexDirection="row">
                  <Box padding={2} component="div">
                    <MailOutlineIcon color="primary" />
                  </Box>
                  <Box py={1} component="div">
                    <Typography variant="body2" color="textSecondary">
                      Email
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="div"
                    >
                      {`${profile.email}`}
                    </Typography>
                  </Box>
                </Box>
                <Box py={1} display="flex" flexDirection="row">
                  <Box padding={2} component="div">
                    <SubscriptionsIcon color="primary" />
                  </Box>
                  <Box py={1} component="div">
                    <Typography variant="body2" color="textSecondary">
                      Subspcrition Status
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="div"
                    >
                      {profile?.subscriber ? "Subscriber" : "No Subscription"}
                    </Typography>
                  </Box>
                </Box>
                {profile?.subscriber && (
                  <Box py={1} display="flex" flexDirection="row">
                    <Box padding={2} component="div">
                      <DateRangeIcon color="primary" />
                    </Box>
                    <Box py={1} component="div">
                      <Typography variant="body2" color="textSecondary">
                        Subspcrition Start Date
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="div"
                      >
                        {moment(profile.subscriptionStartDate).format(
                          "dddd MMMM D Y"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {profile.subscriber && (
                  <Box py={1} display="flex" flexDirection="row">
                    <Box padding={2} component="div">
                      <DateRangeIcon color="primary" />
                    </Box>
                    <Box py={1} component="div">
                      <Typography variant="body2" color="textSecondary">
                        Subspcrition End Date
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="div"
                      >
                        {moment(profile.subscriptionEndDate).format(
                          "dddd MMMM D Y"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {profile.subscriber && (
                  <Box py={1} display="flex" flexDirection="row">
                    <Box padding={2} component="div">
                      <EventAvailableIcon color="primary" />
                    </Box>
                    <Box py={1} component="div">
                      <Typography variant="body2" color="textSecondary">
                        Current Appointments Left
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="div"
                      >
                        {profile.numberOfMeetings}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Container>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default Profile;
