import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';


const InputLabelProps = { shrink: true};

const TextField = ({
    name,
    ...otherProps
}) => {
    const [{value}, {error, touched}, {setValue, setTouched}] = useField(name);
    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return(
        <Box pb={2}>
            <MuiTextField 
            fullWidth
            variant = 'outlined'
            margin = 'dense'
            InputLabelProps={InputLabelProps}
            onChange = {handleChange}
            onBlur={() => setTouched(true)}
            value={value || ''}
            error={error && touched}
            helperText={error && touched ? error : ''}
            {...otherProps}
            />
        </Box>
    );
};

TextField.propTypes = {
    name: PropTypes.string.isRequired
};

export default TextField;