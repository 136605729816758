import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { loadStripe } from "@stripe/stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import uuid from "uuid";
import { functions, db, auth } from "../firebase";
import { toast } from "react-toastify";
const stripePromise = loadStripe(
  "pk_test_51H6dF3CvyJyv0gPzUWUmA7JDVLAryjLjzCn3HZ1P9RHH0sPPhseJ9XyMaJgavdya2NAw92uz16EqEWEzTHW0qXAU00nZiOndjk"
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Select master blaster campaign settings",
    "Create an ad group",
    "Create an ad",
  ];
}

function getStepContent(stepIndex, handleClick) {
  switch (stepIndex) {
    case 0:
      return "Select campaign settings...";
    case 1:
      return "What is an ad group anyways?";
    case 2:
      return (
        <section>
          <div className="product">
            <div className="description">
              <h3>Pharmacist Consultation</h3>
              <h5>$20.00</h5>
            </div>
          </div>
          <button id="checkout-button" role="link" onClick={handleClick}>
            Checkout
          </button>
        </section>
      );
    default:
      return "Unknown stepIndex";
  }
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [user, loading] = useAuthState(auth);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClick = async (event) => {
    try {
      const meetingId = uuid.v4();
      const paymentId = uuid.v4();

      const meeting = {
        id: meetingId,
        question: "test",
        medications: "none",
        healthIssues: "none",
      };
      console.log("creating meeting");
      await db.collection("meetings").add(meeting);
      console.log("meeting created");
      let payment = {
        meetingId: meetingId,
        sessionId: "",
        paymentProcessed: false,
        uid: user.uid,
      };
      console.log("creating payment");
      await db.collection("payments").doc(paymentId).set(payment);

      const stripe = await stripePromise;
      let createSession = await functions.httpsCallable("createPaymentSession");
      let session = await createSession({ paymentId: paymentId });

      await db
        .collection("payments")
        .doc(paymentId)
        .update({ sessionId: session.data.id });
      console.log("updating payment");
      //   When the customer clicks on the button, redirect them to Checkout.
      await stripe.redirectToCheckout({
        sessionId: session.data.id,
      });
    } catch (e) {
      console.error(e);
      toast.error(
        "There was an error creating your payement. Please try again later."
      );
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, handleClick)}
            </Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Checkout" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
