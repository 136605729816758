import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { db } from "../firebase";
import uuid from "uuid";
import Footer from "./Footer";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  paper: {
    padding: "5em",
  },
  typoLeft: {
    textAlign: "left",
    margin: "10px",
  },
  grid: {
    padding: "2em",
  },
}));

const ContactUs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState({
    id: uuid.v4(),
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  let history = useHistory();

  const handleChange = (event) => {
    let newState = { ...value, [event.target.name]: event.target.value };
    setValue(newState);
  };

  const handleSubmitClick = async () => {
    try {
      let response = await db.collection("messages").add(value);
      console.log(response.id);
      history.push("/");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Container align="center">
        <Container maxWidth="sm">
          <Box py={2}>
            <Typography variant="h2">Contact Us</Typography>
          </Box>
          <Box py={2}>
            <TextField
              variant="outlined"
              className={classes.textField}
              name="name"
              label="Your Name"
              fullWidth
              value={value.name}
              onChange={handleChange}
            />
          </Box>
          <Box py={2}>
            <TextField
              variant="outlined"
              className={classes.textField}
              name="email"
              label="Email"
              fullWidth
              value={value.email}
              onChange={handleChange}
            />
          </Box>
          <Box py={2}>
            <TextField
              variant="outlined"
              className={classes.textField}
              name="phone"
              label="Phone"
              fullWidth
              value={value.phone}
              onChange={handleChange}
            />
          </Box>
          <Box py={2}>
            <TextField
              variant="outlined"
              className={classes.textField}
              id="Standard-multiline"
              name="message"
              multiline
              label="Your Message"
              fullWidth
              value={value.message}
              onChange={handleChange}
            />
          </Box>
          <Box py={2}>
            {" "}
            <Button
              variant="contained"
              justify="space-evenly"
              color="primary"
              onClick={handleSubmitClick}
            >
              Submit
            </Button>
          </Box>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </Container>
    </>
  );
};

export default ContactUs;
