import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "./Menu";
import { auth } from "../firebase";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  login: {
    textAlign: "right",
  },
  tabs: {
    flexGrow: 1,
    alignItems: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  hide: {
    display: "none",
  },
}));

const ElevationScroll = (props) => {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const toggleDrawer = (open, event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  useEffect(() => {
    const adminCheck = () => {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          auth.currentUser
            .getIdTokenResult(true)
            .then((idTokenResult) => {
              if (idTokenResult.claims.admin) {
                // 3
                setAdmin(true);
                setLoggedIn(true);
              } else {
                setAdmin(false);
                setLoggedIn(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };
    adminCheck();
  }, []);

  const signOutUser = async () => {
    try {
      await auth.signOut();
      toast.success("Successfully signed out!");
      setLoggedIn(false);
      setAdmin(false);
      history.push("/");
    } catch (e) {
      toast.error("Error signing out!");
    }
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          className={classes.appBar}
          style={{
            background: props.theme.palette.primary.mainGradient,
          }}
          position="sticky"
        >
          <Container>
            <Toolbar>
              {admin && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setOpen(true)}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h6"
                onClick={() => history.push("/")}
                className={classes.title}
              >
                APCS
              </Typography>
              {!loggedIn && (
                <Tooltip title="Signin" aria-label="add">
                  <IconButton
                    onClick={() => history.push("/login")}
                    className={classes.login}
                  >
                    <PersonOutlineIcon style={{ fill: "white" }} />
                  </IconButton>
                </Tooltip>
              )}
              {loggedIn && (
                <Tooltip title="Profile" aria-label="add">
                  <IconButton
                    onClick={() => history.push("/profile")}
                    className={classes.login}
                  >
                    <PersonOutlineIcon style={{ fill: "white" }} />
                  </IconButton>
                </Tooltip>
              )}
              {loggedIn && (
                <Tooltip title="Logout" aria-label="add">
                  <IconButton onClick={signOutUser} className={classes.login}>
                    <ExitToAppIcon style={{ fill: "white" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Menu open={open} toggleDrawer={toggleDrawer} />
    </>
  );
}
