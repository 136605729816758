import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyC2qJdVf4ANdfr9AoW6N2oM0SRKOhsw3z8",
  authDomain: "acps-6a363.firebaseapp.com",
  appId: "1:923311878808:web:a7bd2b852949b3c808d6ed",
  projectId: "acps-6a363",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
