import React, { useState, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { auth, db } from "../firebase";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAuthState } from "react-firebase-hooks/auth";
import Box from "@material-ui/core/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "./TextField";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const formSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  password: Yup.string().required("Password is required.").min(6),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match."
  ),
  email: Yup.string().email().required("Email is required."),
});

export default function Signup() {
  const classes = useStyles();
  let history = useHistory();
  const [value, setValue] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
  });
  let initialValidation = useRef(true);

  const handleSubmitClick = async (values) => {
    try {
      let response = await auth.createUserWithEmailAndPassword(
        values.email,
        values.password
      );
      await db.collection("profile").doc(response.user.uid).set({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        subscriber: false,
      });
      await auth.currentUser.sendEmailVerification();
      toast.success(
        "Successfully signed up! You will be sent a verification email."
      );
      history.push("/");
    } catch (e) {
      console.log(e);
      if (e.code === "auth/email-already-in-use") {
        toast.error(e.message);
      } else {
        toast.error(`There was an error creating your account.`);
      }
    }
  };

  return (
    <Formik
      initialValues={value}
      validationSchema={formSchema}
      enableReinitialize
      onSubmit={(values) => {
        handleSubmitClick(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        validateOnBlur,
        validateForm,
        isSubmitting,
        isValid,
      }) => {
        if (initialValidation.current) {
          validateForm();
          initialValidation.current = false;
        }
        return (
          <>
            <Container className={classes.paper} maxWidth="xs">
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  margin: "1em",
                }}
              >
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
              </Box>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>

              <Box py={3}>
                <TextField
                  name="email"
                  label="Email"
                  inputProps={{ maxLength: 100 }}
                />
                <TextField
                  name="firstName"
                  label="First Name"
                  inputProps={{ maxLength: 30 }}
                />
                <TextField
                  name="lastName"
                  label="Last Name"
                  inputProps={{ maxLength: 30 }}
                />
                <TextField
                  name="password"
                  label="Password"
                  inputProps={{ maxLength: 100 }}
                />
                <TextField
                  name="passwordConfirmation"
                  label="Confirm Password"
                  inputProps={{ maxLength: 100 }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  Sign up
                </Button>
              </Box>
            </Container>
          </>
        );
      }}
    </Formik>
  );
}
