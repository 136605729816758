import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { auth, functions } from "../firebase";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LogIn() {
  const classes = useStyles();
  let history = useHistory();
  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const [recap, setRecap] = useState(false);

  const signInWithEmailAndPasswordHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await auth.signInWithEmailAndPassword(
        value.email,
        value.password
      );
      toast.success("Sucessfully Signed in!");
      console.log(response);
      history.goBack();
    } catch (error) {
      toast.error("Invalid email and password!");
      console.error("Error signing in with email and password", error);
    }
  };

  const handleRecaptcha = async (event) => {
    try {
      console.log(event);
      let checkRecaptcha = await functions.httpsCallable("checkRecaptcha");
      let verify = await checkRecaptcha({ response: event });
      console.log(verify.data);
      if (verify.data === "success") {
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            value={value.email}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setValue({ ...value, email: e.target.value })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={value.password}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setValue({ ...value, password: e.target.value })}
          />

          <Typography>
            <Link
              color="textPrimary"
              onClick={() => {
                history.push("/forgotpassword");
              }}
            >
              Forgot Password?
            </Link>
          </Typography>

          {/* <ReCAPTCHA
            sitekey="6LdNOwIaAAAAAO0UZRRYC8d4kBcE_smIZd5H611m"
            onChange={handleRecaptcha}
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signInWithEmailAndPasswordHandler}
            // disabled={!recap}
          >
            Sign In
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#3498db", color: "white" }}
            className={classes.submit}
            onClick={() => {
              history.push("/signup");
            }}
          >
            sign up
          </Button>
        </form>
      </div>
    </Container>
  );
}
